body {
  display: flex;
  justify-content: center;
}

fieldset {
  border: 2px dashed #1a1718;
  font-family: "Merriweather", serif;
}

footer {
  display: flex;
  padding: 0 2rem;
  margin-top: 3rem;
  text-align: center;
  flex-direction: column;
}

.avatar {
  border-radius: 50%;
}

.cleave {
  width: 100%;
  border: none;
  outline: none;
  font-size: 2rem;
  color: #1a1718;
  font-weight: bold;
  padding: 0.3rem 0;
  text-align: center;
  font-family: "Roboto";
  background-color: transparent;
}

.container {
  margin: 2rem;
}

.logo {
  margin: 2rem 1rem;
}

.margin > * {
  margin: 1rem 0;
}

.pointer-cursor {
  cursor: pointer;
}

.star {
  bottom: 4px;
  color: #a593e0;
  position: relative;
  margin-left: 0.4rem;
}

.strip-decoration {
  color: inherit;
  text-decoration: none;
}

.user-profile > * {
  margin: 1rem 0;
}

.wrapper {
  display: flex;
  margin-top: 3rem;
  max-width: 500px;
  text-align: center;
  border-radius: 10px;
  background-color: floralwhite;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
}

@media only screen and (max-width: 500px) {
  body {
    background-color: #1a1718;
  }

  footer {
    padding: 2rem;
    margin-top: 0;
  }

  .wrapper {
    margin: 0;
    box-shadow: none;
  }
}
